import React from "react";
import { PARYOLL_DATA } from "data/pages";
import cx from "classnames";

import * as styles from "./PayrollForTeams.module.scss";

const PayrollForTeams: React.FC = () => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  return (
    <section className={styles.PayrollForTeams}>
      <header className={styles.PayrollForTeams_header}>
        <h1 className={styles.PayrollForTeams_header_title}>
          The easiest and most useful way to run payroll for your teams
        </h1>
      </header>

      <ul className={styles.PayrollForTeams_content}>
        {PARYOLL_DATA.PAYROLL_FOR_TEAMS.map(({ text, title, img }, index) => (
          <li key={index} className={styles.PayrollForTeams_content_item}>
            <header className={styles.PayrollForTeams_content_item_header}>
              <h1 className={styles.PayrollForTeams_content_item_header_title}>
                {title}
              </h1>
              <h4 className={styles.PayrollForTeams_content_item_header_text}>
                {text}
              </h4>
            </header>
            <img src={img} alt={title} />
          </li>
        ))}
      </ul>

      <div className={styles.PayrollForTeams_mobile}>
        <div className={styles.PayrollForTeams_mobile_content}>
          <header className={styles.PayrollForTeams_mobile_content_header}>
            <h1 className={styles.PayrollForTeams_mobile_content_header_title}>
              {PARYOLL_DATA.PAYROLL_FOR_TEAMS[activeIndex].title}
            </h1>
            <h4 className={styles.PayrollForTeams_mobile_content_header_text}>
              {PARYOLL_DATA.PAYROLL_FOR_TEAMS[activeIndex].text}
            </h4>
          </header>

          <img
            src={PARYOLL_DATA.PAYROLL_FOR_TEAMS[activeIndex].img}
            alt={PARYOLL_DATA.PAYROLL_FOR_TEAMS[activeIndex].title}
            className={styles.PayrollForTeams_mobile_content_header_img}
          />
        </div>

        <div className={styles.PayrollForTeams_mobile_selectors}>
          {PARYOLL_DATA.PAYROLL_FOR_TEAMS.map((_, index) => (
            <div
              key={index}
              className={cx(styles.PayrollForTeams_mobile_selectors_selector, {
                [styles.PayrollForTeams_mobile_selectors_selector_active]:
                  activeIndex === index,
              })}
              onClick={() => setActiveIndex(index)}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default PayrollForTeams;

// extracted by mini-css-extract-plugin
export var RelatedPost = "RelatedPost-module--RelatedPost--5tbl5";
export var RelatedPost_container = "RelatedPost-module--RelatedPost_container--Fkzz2";
export var RelatedPost_content = "RelatedPost-module--RelatedPost_content--Ov8mN";
export var RelatedPost_content_item = "RelatedPost-module--RelatedPost_content_item--vAXEA";
export var RelatedPost_content_item_details = "RelatedPost-module--RelatedPost_content_item_details--tiMWS";
export var RelatedPost_content_item_details_excerpt = "RelatedPost-module--RelatedPost_content_item_details_excerpt--L6BHS";
export var RelatedPost_content_item_details_title = "RelatedPost-module--RelatedPost_content_item_details_title--dOiy2";
export var RelatedPost_content_item_img = "RelatedPost-module--RelatedPost_content_item_img--msxmh";
export var RelatedPost_header = "RelatedPost-module--RelatedPost_header--TPlgG";
export var RelatedPost_header_title = "RelatedPost-module--RelatedPost_header_title--MPBWU";
export var container = "RelatedPost-module--container--01WqH";
export var gatsbyImageWrapperConstrained = "RelatedPost-module--gatsby-image-wrapper-constrained--yWNOw";
export var gridSpan1010 = "RelatedPost-module--grid-span-10-10--JlH9T";
export var gridSpan1011 = "RelatedPost-module--grid-span-10-11--arunJ";
export var gridSpan102 = "RelatedPost-module--grid-span-10-2--tG1Y7";
export var gridSpan103 = "RelatedPost-module--grid-span-10-3--w0-ZO";
export var gridSpan104 = "RelatedPost-module--grid-span-10-4--8K6iA";
export var gridSpan105 = "RelatedPost-module--grid-span-10-5--VjGdO";
export var gridSpan106 = "RelatedPost-module--grid-span-10-6--uWxFO";
export var gridSpan107 = "RelatedPost-module--grid-span-10-7--gtO9O";
export var gridSpan108 = "RelatedPost-module--grid-span-10-8--V8+kM";
export var gridSpan109 = "RelatedPost-module--grid-span-10-9--SmZto";
export var gridSpan110 = "RelatedPost-module--grid-span-1-10--d1Tab";
export var gridSpan111 = "RelatedPost-module--grid-span-1-11--xwhT4";
export var gridSpan1110 = "RelatedPost-module--grid-span-11-10--3fsUk";
export var gridSpan1111 = "RelatedPost-module--grid-span-11-11--zUx7c";
export var gridSpan112 = "RelatedPost-module--grid-span-11-2--FosBS";
export var gridSpan113 = "RelatedPost-module--grid-span-11-3--pzTst";
export var gridSpan114 = "RelatedPost-module--grid-span-11-4--dyPK2";
export var gridSpan115 = "RelatedPost-module--grid-span-11-5--deWz-";
export var gridSpan116 = "RelatedPost-module--grid-span-11-6--i5Pwc";
export var gridSpan117 = "RelatedPost-module--grid-span-11-7--jeKJl";
export var gridSpan118 = "RelatedPost-module--grid-span-11-8--uAt5O";
export var gridSpan119 = "RelatedPost-module--grid-span-11-9--0MAXh";
export var gridSpan12 = "RelatedPost-module--grid-span-1-2--fEFvy";
export var gridSpan1210 = "RelatedPost-module--grid-span-12-10--s3PjJ";
export var gridSpan1211 = "RelatedPost-module--grid-span-12-11--Wg7ie";
export var gridSpan122 = "RelatedPost-module--grid-span-12-2--VnXp7";
export var gridSpan123 = "RelatedPost-module--grid-span-12-3--VWJJ1";
export var gridSpan124 = "RelatedPost-module--grid-span-12-4--Mdbu0";
export var gridSpan125 = "RelatedPost-module--grid-span-12-5--my5Cf";
export var gridSpan126 = "RelatedPost-module--grid-span-12-6--pCHQ+";
export var gridSpan127 = "RelatedPost-module--grid-span-12-7--hUDdR";
export var gridSpan128 = "RelatedPost-module--grid-span-12-8--a1krS";
export var gridSpan129 = "RelatedPost-module--grid-span-12-9--Fld0C";
export var gridSpan13 = "RelatedPost-module--grid-span-1-3--CXHiy";
export var gridSpan14 = "RelatedPost-module--grid-span-1-4--8oZWt";
export var gridSpan15 = "RelatedPost-module--grid-span-1-5--AwU4o";
export var gridSpan16 = "RelatedPost-module--grid-span-1-6--JCuFN";
export var gridSpan17 = "RelatedPost-module--grid-span-1-7--OBOtT";
export var gridSpan18 = "RelatedPost-module--grid-span-1-8--BE+LE";
export var gridSpan19 = "RelatedPost-module--grid-span-1-9--C5ZTf";
export var gridSpan210 = "RelatedPost-module--grid-span-2-10--l1xHI";
export var gridSpan211 = "RelatedPost-module--grid-span-2-11--vyO08";
export var gridSpan22 = "RelatedPost-module--grid-span-2-2--gxa9Z";
export var gridSpan23 = "RelatedPost-module--grid-span-2-3--sEupP";
export var gridSpan24 = "RelatedPost-module--grid-span-2-4--gbr5G";
export var gridSpan25 = "RelatedPost-module--grid-span-2-5--G-yEs";
export var gridSpan26 = "RelatedPost-module--grid-span-2-6--OeKMQ";
export var gridSpan27 = "RelatedPost-module--grid-span-2-7--RmJvI";
export var gridSpan28 = "RelatedPost-module--grid-span-2-8--9TLE6";
export var gridSpan29 = "RelatedPost-module--grid-span-2-9--beSf+";
export var gridSpan310 = "RelatedPost-module--grid-span-3-10--hzJl6";
export var gridSpan311 = "RelatedPost-module--grid-span-3-11--vrFiu";
export var gridSpan32 = "RelatedPost-module--grid-span-3-2--HF0hz";
export var gridSpan33 = "RelatedPost-module--grid-span-3-3--jBVli";
export var gridSpan34 = "RelatedPost-module--grid-span-3-4--UeMqJ";
export var gridSpan35 = "RelatedPost-module--grid-span-3-5--mDJxn";
export var gridSpan36 = "RelatedPost-module--grid-span-3-6--zDgOt";
export var gridSpan37 = "RelatedPost-module--grid-span-3-7--kcQ85";
export var gridSpan38 = "RelatedPost-module--grid-span-3-8--6Se5K";
export var gridSpan39 = "RelatedPost-module--grid-span-3-9--gNTYZ";
export var gridSpan410 = "RelatedPost-module--grid-span-4-10--HpF8N";
export var gridSpan411 = "RelatedPost-module--grid-span-4-11--m05YQ";
export var gridSpan42 = "RelatedPost-module--grid-span-4-2--Bf2Hp";
export var gridSpan43 = "RelatedPost-module--grid-span-4-3--iadQM";
export var gridSpan44 = "RelatedPost-module--grid-span-4-4--zX4wd";
export var gridSpan45 = "RelatedPost-module--grid-span-4-5--zDbW5";
export var gridSpan46 = "RelatedPost-module--grid-span-4-6--+AvK2";
export var gridSpan47 = "RelatedPost-module--grid-span-4-7--WT22G";
export var gridSpan48 = "RelatedPost-module--grid-span-4-8--k7PE1";
export var gridSpan49 = "RelatedPost-module--grid-span-4-9--B8YuK";
export var gridSpan510 = "RelatedPost-module--grid-span-5-10--SnE2H";
export var gridSpan511 = "RelatedPost-module--grid-span-5-11--vLkKl";
export var gridSpan52 = "RelatedPost-module--grid-span-5-2--Bienn";
export var gridSpan53 = "RelatedPost-module--grid-span-5-3--l0irG";
export var gridSpan54 = "RelatedPost-module--grid-span-5-4--qRODM";
export var gridSpan55 = "RelatedPost-module--grid-span-5-5--4xvNG";
export var gridSpan56 = "RelatedPost-module--grid-span-5-6--cNcGZ";
export var gridSpan57 = "RelatedPost-module--grid-span-5-7--b-lEw";
export var gridSpan58 = "RelatedPost-module--grid-span-5-8--Ey-fH";
export var gridSpan59 = "RelatedPost-module--grid-span-5-9--SflbE";
export var gridSpan610 = "RelatedPost-module--grid-span-6-10--Eo5aN";
export var gridSpan611 = "RelatedPost-module--grid-span-6-11--S7rJX";
export var gridSpan62 = "RelatedPost-module--grid-span-6-2--FiRUm";
export var gridSpan63 = "RelatedPost-module--grid-span-6-3--IZE-u";
export var gridSpan64 = "RelatedPost-module--grid-span-6-4--+ttM2";
export var gridSpan65 = "RelatedPost-module--grid-span-6-5--aLRGT";
export var gridSpan66 = "RelatedPost-module--grid-span-6-6--S6gsi";
export var gridSpan67 = "RelatedPost-module--grid-span-6-7--O5rZ8";
export var gridSpan68 = "RelatedPost-module--grid-span-6-8--NEc-j";
export var gridSpan69 = "RelatedPost-module--grid-span-6-9--xn5AA";
export var gridSpan710 = "RelatedPost-module--grid-span-7-10--pnXDG";
export var gridSpan711 = "RelatedPost-module--grid-span-7-11--i0eMp";
export var gridSpan72 = "RelatedPost-module--grid-span-7-2--4K2Jj";
export var gridSpan73 = "RelatedPost-module--grid-span-7-3--c4lA8";
export var gridSpan74 = "RelatedPost-module--grid-span-7-4--LsPmN";
export var gridSpan75 = "RelatedPost-module--grid-span-7-5--42icH";
export var gridSpan76 = "RelatedPost-module--grid-span-7-6--i+T90";
export var gridSpan77 = "RelatedPost-module--grid-span-7-7--XvlHX";
export var gridSpan78 = "RelatedPost-module--grid-span-7-8--+jcI5";
export var gridSpan79 = "RelatedPost-module--grid-span-7-9--0HKBU";
export var gridSpan810 = "RelatedPost-module--grid-span-8-10--or0oX";
export var gridSpan811 = "RelatedPost-module--grid-span-8-11--iR3fc";
export var gridSpan82 = "RelatedPost-module--grid-span-8-2--N0pl2";
export var gridSpan83 = "RelatedPost-module--grid-span-8-3--Bb2pS";
export var gridSpan84 = "RelatedPost-module--grid-span-8-4--hV5AL";
export var gridSpan85 = "RelatedPost-module--grid-span-8-5--YbK04";
export var gridSpan86 = "RelatedPost-module--grid-span-8-6--ZcyPz";
export var gridSpan87 = "RelatedPost-module--grid-span-8-7--aTwp+";
export var gridSpan88 = "RelatedPost-module--grid-span-8-8--MadtH";
export var gridSpan89 = "RelatedPost-module--grid-span-8-9--GUdaB";
export var gridSpan910 = "RelatedPost-module--grid-span-9-10--7eN2g";
export var gridSpan911 = "RelatedPost-module--grid-span-9-11--FcprG";
export var gridSpan92 = "RelatedPost-module--grid-span-9-2--K6zAx";
export var gridSpan93 = "RelatedPost-module--grid-span-9-3--ynTqk";
export var gridSpan94 = "RelatedPost-module--grid-span-9-4--OnEG+";
export var gridSpan95 = "RelatedPost-module--grid-span-9-5--RSLMl";
export var gridSpan96 = "RelatedPost-module--grid-span-9-6---n7x2";
export var gridSpan97 = "RelatedPost-module--grid-span-9-7--xLErV";
export var gridSpan98 = "RelatedPost-module--grid-span-9-8--483iy";
export var gridSpan99 = "RelatedPost-module--grid-span-9-9--JXn+c";
export var textBreak = "RelatedPost-module--text-break--gE9RT";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { PARYOLL_DATA } from "data/pages";
import { IconCircleArrow } from "assets/media/svgs";
import { Scrollbar } from "components/design-system";

import * as styles from "./Services.module.scss";

const Services: React.FC = () => {
  const [activeIndex, setActiveIndex] = React.useState(0);

  const contentRef = React.useRef<HTMLDivElement>(null);

  return (
    <section className={styles.Services}>
      <header className={styles.Services_header}>
        <div className={styles.Services_header_img}>
          <StaticImage
            src={`../../../assets/media/images/payroll/service_img.png`}
            alt="Services"
          />
        </div>
        <div className={styles.Services_header_content}>
          <h1 className={styles.Services_header_content_title}>
            Built for your business with even more financial tools added for
            your team
          </h1>
          <p className={styles.Services_header_content_text}>
            All of the tools and services your team would require for financial
            prosperity and freedom are included.
          </p>
        </div>
      </header>

      <div className={styles.Services_content} ref={contentRef}>
        {PARYOLL_DATA.SERVICES.map(({ text, img }, index) => (
          <div key={index} className={styles.Services_content_item}>
            <div className={styles.Services_content_item_img_wrapper}>
              <img
                src={img}
                alt="services"
                className={styles.Services_content_item_img}
              />
            </div>

            <div className={styles.Services_content_item_text}>{text}</div>
          </div>
        ))}
      </div>

      <div className={styles.Services_scrollbar}>
        <Scrollbar contentRef={contentRef} hasButton />
      </div>

      <div className={styles.Services_mobile}>
        <div className={styles.Services_mobile_item}>
          <div className={styles.Services_mobile_item_img_wrapper}>
            <img
              src={PARYOLL_DATA.SERVICES[activeIndex].img}
              alt="services"
              className={styles.Services_mobile_item_img}
            />
          </div>
          {PARYOLL_DATA.SERVICES[activeIndex].text}
        </div>

        <div className={styles.Services_mobile_controls}>
          <button
            onClick={() => setActiveIndex(activeIndex - 1)}
            disabled={activeIndex === 0}
            className={styles.Services_mobile_controls_btn}
          >
            <IconCircleArrow />
          </button>
          <button
            onClick={() => setActiveIndex(activeIndex + 1)}
            disabled={activeIndex === PARYOLL_DATA.SERVICES.length - 1}
            className={styles.Services_mobile_controls_btn}
          >
            <IconCircleArrow />
          </button>
        </div>
      </div>
    </section>
  );
};

export default Services;

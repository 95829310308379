import React from "react";
import { PARYOLL_DATA } from "data/pages";

import * as styles from "./Customers.module.scss";

const Customers: React.FC = () => {
  return (
    <section className={styles.Customers}>
      <header className={styles.Customers_header}>
        <h1 className={styles.Customers_header_title}>
          Spend 90% less time on payroll every month. Gain visibility and make
          better decisions
        </h1>
      </header>
      <ul className={styles.Customers_content}>
        {PARYOLL_DATA.CUSTOMERS.map(({ img, role }, index) => (
          <li key={index}>
            <img
              src={img}
              alt={role}
              className={styles.Customers_content_img}
            />
            <p className={styles.Customers_content_role}>
              {role}, a Brass customer
            </p>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Customers;

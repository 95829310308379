// extracted by mini-css-extract-plugin
export var Platform = "Platform-module--Platform--WRUUf";
export var Platform_container = "Platform-module--Platform_container--zjzeV";
export var Platform_content = "Platform-module--Platform_content--I4M26";
export var Platform_content_item = "Platform-module--Platform_content_item--aDuk6";
export var Platform_content_item_img = "Platform-module--Platform_content_item_img--e2C+C";
export var Platform_content_item_text = "Platform-module--Platform_content_item_text--bSuky";
export var Platform_header = "Platform-module--Platform_header--EoYw0";
export var Platform_header_text = "Platform-module--Platform_header_text--0T0OC";
export var Platform_header_title = "Platform-module--Platform_header_title--kNCMn";
export var container = "Platform-module--container--z0nR8";
export var gatsbyImageWrapperConstrained = "Platform-module--gatsby-image-wrapper-constrained--w65qI";
export var gridSpan1010 = "Platform-module--grid-span-10-10--l8k28";
export var gridSpan1011 = "Platform-module--grid-span-10-11--pR2VA";
export var gridSpan102 = "Platform-module--grid-span-10-2--4UXBM";
export var gridSpan103 = "Platform-module--grid-span-10-3--2JWQv";
export var gridSpan104 = "Platform-module--grid-span-10-4--YidEo";
export var gridSpan105 = "Platform-module--grid-span-10-5--+nAi7";
export var gridSpan106 = "Platform-module--grid-span-10-6--BGHsK";
export var gridSpan107 = "Platform-module--grid-span-10-7--NileW";
export var gridSpan108 = "Platform-module--grid-span-10-8--pjx9b";
export var gridSpan109 = "Platform-module--grid-span-10-9--i0+Tu";
export var gridSpan110 = "Platform-module--grid-span-1-10--h-6b3";
export var gridSpan111 = "Platform-module--grid-span-1-11--WFnmz";
export var gridSpan1110 = "Platform-module--grid-span-11-10--aQuqO";
export var gridSpan1111 = "Platform-module--grid-span-11-11--Q5ARV";
export var gridSpan112 = "Platform-module--grid-span-11-2--yByae";
export var gridSpan113 = "Platform-module--grid-span-11-3--wQnh9";
export var gridSpan114 = "Platform-module--grid-span-11-4--FrKhk";
export var gridSpan115 = "Platform-module--grid-span-11-5--WMqXX";
export var gridSpan116 = "Platform-module--grid-span-11-6--nUnhe";
export var gridSpan117 = "Platform-module--grid-span-11-7--VGKfh";
export var gridSpan118 = "Platform-module--grid-span-11-8--t5wqq";
export var gridSpan119 = "Platform-module--grid-span-11-9--kMVhW";
export var gridSpan12 = "Platform-module--grid-span-1-2--oDGN8";
export var gridSpan1210 = "Platform-module--grid-span-12-10--5Cmwd";
export var gridSpan1211 = "Platform-module--grid-span-12-11--6pf8T";
export var gridSpan122 = "Platform-module--grid-span-12-2--e5RjO";
export var gridSpan123 = "Platform-module--grid-span-12-3--nLvUK";
export var gridSpan124 = "Platform-module--grid-span-12-4--qcXo7";
export var gridSpan125 = "Platform-module--grid-span-12-5--Xu35k";
export var gridSpan126 = "Platform-module--grid-span-12-6--MVXJW";
export var gridSpan127 = "Platform-module--grid-span-12-7--dT4+q";
export var gridSpan128 = "Platform-module--grid-span-12-8--tlqG4";
export var gridSpan129 = "Platform-module--grid-span-12-9--uz308";
export var gridSpan13 = "Platform-module--grid-span-1-3--iWS31";
export var gridSpan14 = "Platform-module--grid-span-1-4--Fjyl5";
export var gridSpan15 = "Platform-module--grid-span-1-5--Nw+Vb";
export var gridSpan16 = "Platform-module--grid-span-1-6--n+oKg";
export var gridSpan17 = "Platform-module--grid-span-1-7--10mO+";
export var gridSpan18 = "Platform-module--grid-span-1-8--IYmXN";
export var gridSpan19 = "Platform-module--grid-span-1-9--3zIDI";
export var gridSpan210 = "Platform-module--grid-span-2-10--4WZ5I";
export var gridSpan211 = "Platform-module--grid-span-2-11--pozCr";
export var gridSpan22 = "Platform-module--grid-span-2-2--OOhdu";
export var gridSpan23 = "Platform-module--grid-span-2-3--xit63";
export var gridSpan24 = "Platform-module--grid-span-2-4--W8qCe";
export var gridSpan25 = "Platform-module--grid-span-2-5--0tsOm";
export var gridSpan26 = "Platform-module--grid-span-2-6--7IdAz";
export var gridSpan27 = "Platform-module--grid-span-2-7--4Dyxd";
export var gridSpan28 = "Platform-module--grid-span-2-8--evmC8";
export var gridSpan29 = "Platform-module--grid-span-2-9--XbG7o";
export var gridSpan310 = "Platform-module--grid-span-3-10--2HZV0";
export var gridSpan311 = "Platform-module--grid-span-3-11--vgUYh";
export var gridSpan32 = "Platform-module--grid-span-3-2--hRHCt";
export var gridSpan33 = "Platform-module--grid-span-3-3--csNRN";
export var gridSpan34 = "Platform-module--grid-span-3-4--scWyk";
export var gridSpan35 = "Platform-module--grid-span-3-5--QYtFR";
export var gridSpan36 = "Platform-module--grid-span-3-6--PA0Ri";
export var gridSpan37 = "Platform-module--grid-span-3-7--dHa07";
export var gridSpan38 = "Platform-module--grid-span-3-8--779mH";
export var gridSpan39 = "Platform-module--grid-span-3-9--G5ITF";
export var gridSpan410 = "Platform-module--grid-span-4-10--CFac4";
export var gridSpan411 = "Platform-module--grid-span-4-11--X63-p";
export var gridSpan42 = "Platform-module--grid-span-4-2--cj-yk";
export var gridSpan43 = "Platform-module--grid-span-4-3--HFo5e";
export var gridSpan44 = "Platform-module--grid-span-4-4---CGmV";
export var gridSpan45 = "Platform-module--grid-span-4-5--esxuj";
export var gridSpan46 = "Platform-module--grid-span-4-6--cjkQ8";
export var gridSpan47 = "Platform-module--grid-span-4-7--vZvd8";
export var gridSpan48 = "Platform-module--grid-span-4-8--2OkbI";
export var gridSpan49 = "Platform-module--grid-span-4-9---UuJR";
export var gridSpan510 = "Platform-module--grid-span-5-10--XbIvW";
export var gridSpan511 = "Platform-module--grid-span-5-11--EufS7";
export var gridSpan52 = "Platform-module--grid-span-5-2--tpNCl";
export var gridSpan53 = "Platform-module--grid-span-5-3--3Pr+Z";
export var gridSpan54 = "Platform-module--grid-span-5-4--hEcFt";
export var gridSpan55 = "Platform-module--grid-span-5-5--4E0IB";
export var gridSpan56 = "Platform-module--grid-span-5-6--5fl2y";
export var gridSpan57 = "Platform-module--grid-span-5-7--HmLUj";
export var gridSpan58 = "Platform-module--grid-span-5-8--YPHZV";
export var gridSpan59 = "Platform-module--grid-span-5-9--+ya3j";
export var gridSpan610 = "Platform-module--grid-span-6-10--1Y2n4";
export var gridSpan611 = "Platform-module--grid-span-6-11--6NeJH";
export var gridSpan62 = "Platform-module--grid-span-6-2--pVJab";
export var gridSpan63 = "Platform-module--grid-span-6-3--gO-Uo";
export var gridSpan64 = "Platform-module--grid-span-6-4--tzMpy";
export var gridSpan65 = "Platform-module--grid-span-6-5--awAcR";
export var gridSpan66 = "Platform-module--grid-span-6-6--TIpH4";
export var gridSpan67 = "Platform-module--grid-span-6-7--GoI8V";
export var gridSpan68 = "Platform-module--grid-span-6-8--rlfvW";
export var gridSpan69 = "Platform-module--grid-span-6-9--s+7Dj";
export var gridSpan710 = "Platform-module--grid-span-7-10--ppiCQ";
export var gridSpan711 = "Platform-module--grid-span-7-11--Nlg8W";
export var gridSpan72 = "Platform-module--grid-span-7-2--dBqJy";
export var gridSpan73 = "Platform-module--grid-span-7-3--OXJPd";
export var gridSpan74 = "Platform-module--grid-span-7-4--H1pc7";
export var gridSpan75 = "Platform-module--grid-span-7-5--V76rh";
export var gridSpan76 = "Platform-module--grid-span-7-6--7AE-J";
export var gridSpan77 = "Platform-module--grid-span-7-7--tsXfn";
export var gridSpan78 = "Platform-module--grid-span-7-8--HPAGJ";
export var gridSpan79 = "Platform-module--grid-span-7-9--89OLt";
export var gridSpan810 = "Platform-module--grid-span-8-10--Q7o7a";
export var gridSpan811 = "Platform-module--grid-span-8-11--tTxxJ";
export var gridSpan82 = "Platform-module--grid-span-8-2--Zz7r1";
export var gridSpan83 = "Platform-module--grid-span-8-3--73B2m";
export var gridSpan84 = "Platform-module--grid-span-8-4--4I6Xw";
export var gridSpan85 = "Platform-module--grid-span-8-5--ppuaC";
export var gridSpan86 = "Platform-module--grid-span-8-6--DLM4K";
export var gridSpan87 = "Platform-module--grid-span-8-7--MhxCQ";
export var gridSpan88 = "Platform-module--grid-span-8-8--FIG-h";
export var gridSpan89 = "Platform-module--grid-span-8-9--9OcWx";
export var gridSpan910 = "Platform-module--grid-span-9-10--TjwJO";
export var gridSpan911 = "Platform-module--grid-span-9-11--XjpE7";
export var gridSpan92 = "Platform-module--grid-span-9-2--RVUCL";
export var gridSpan93 = "Platform-module--grid-span-9-3--7EhfY";
export var gridSpan94 = "Platform-module--grid-span-9-4--d8LpU";
export var gridSpan95 = "Platform-module--grid-span-9-5--yvOkX";
export var gridSpan96 = "Platform-module--grid-span-9-6--JdWOG";
export var gridSpan97 = "Platform-module--grid-span-9-7--ds0nn";
export var gridSpan98 = "Platform-module--grid-span-9-8--lZdlU";
export var gridSpan99 = "Platform-module--grid-span-9-9--eeYsk";
export var textBreak = "Platform-module--text-break--7tiKH";
// extracted by mini-css-extract-plugin
export var Pricing = "Pricing-module--Pricing--jet2Z";
export var PricingCard = "Pricing-module--PricingCard--VDafD";
export var PricingCard_features = "Pricing-module--PricingCard_features--8+3TD";
export var PricingCard_features_data = "Pricing-module--PricingCard_features_data--mmepG";
export var PricingCard_features_data_item = "Pricing-module--PricingCard_features_data_item--OZBuC";
export var PricingCard_header = "Pricing-module--PricingCard_header--0QUwp";
export var PricingCard_header_company = "Pricing-module--PricingCard_header_company--6Obmj";
export var PricingCard_header_cost = "Pricing-module--PricingCard_header_cost--opOFy";
export var PricingCard_header_fee = "Pricing-module--PricingCard_header_fee--9WhVf";
export var Pricing_container = "Pricing-module--Pricing_container--I5imI";
export var Pricing_content = "Pricing-module--Pricing_content--h79O5";
export var Pricing_content_details = "Pricing-module--Pricing_content_details--ldB44";
export var Pricing_content_standard = "Pricing-module--Pricing_content_standard--F5ANi";
export var Pricing_content_standard_text = "Pricing-module--Pricing_content_standard_text--BNdw8";
export var Pricing_header = "Pricing-module--Pricing_header--PoOE4";
export var Pricing_header_subtitle = "Pricing-module--Pricing_header_subtitle--ATu-6";
export var Pricing_header_title = "Pricing-module--Pricing_header_title--DbcEf";
export var Pricing_mobile = "Pricing-module--Pricing_mobile--7IcjL";
export var Pricing_mobile_controls = "Pricing-module--Pricing_mobile_controls--1Z0ai";
export var Pricing_mobile_controls_btn = "Pricing-module--Pricing_mobile_controls_btn--SFQ95";
export var Pricing_simple = "Pricing-module--Pricing_simple--SkN33";
export var Pricing_tab = "Pricing-module--Pricing_tab--uRDjK";
export var Pricing_tab_btn = "Pricing-module--Pricing_tab_btn--H7ZNg";
export var Pricing_tab_btn_active = "Pricing-module--Pricing_tab_btn_active--HIp3l";
export var container = "Pricing-module--container--2Ca-+";
export var gatsbyImageWrapperConstrained = "Pricing-module--gatsby-image-wrapper-constrained--oSEoB";
export var gridSpan1010 = "Pricing-module--grid-span-10-10--93mnE";
export var gridSpan1011 = "Pricing-module--grid-span-10-11--kbHeV";
export var gridSpan102 = "Pricing-module--grid-span-10-2--1yi6W";
export var gridSpan103 = "Pricing-module--grid-span-10-3--THEor";
export var gridSpan104 = "Pricing-module--grid-span-10-4---vi1J";
export var gridSpan105 = "Pricing-module--grid-span-10-5--Fgd4o";
export var gridSpan106 = "Pricing-module--grid-span-10-6--CJ2F7";
export var gridSpan107 = "Pricing-module--grid-span-10-7--oO1SQ";
export var gridSpan108 = "Pricing-module--grid-span-10-8--Otgd-";
export var gridSpan109 = "Pricing-module--grid-span-10-9--mtHcp";
export var gridSpan110 = "Pricing-module--grid-span-1-10--zROvz";
export var gridSpan111 = "Pricing-module--grid-span-1-11--Ydt2i";
export var gridSpan1110 = "Pricing-module--grid-span-11-10--Ax+Q9";
export var gridSpan1111 = "Pricing-module--grid-span-11-11--UIpHZ";
export var gridSpan112 = "Pricing-module--grid-span-11-2---3oX1";
export var gridSpan113 = "Pricing-module--grid-span-11-3--ZOyCX";
export var gridSpan114 = "Pricing-module--grid-span-11-4--dq+7e";
export var gridSpan115 = "Pricing-module--grid-span-11-5--I4UMu";
export var gridSpan116 = "Pricing-module--grid-span-11-6--ZzPPb";
export var gridSpan117 = "Pricing-module--grid-span-11-7--6IumH";
export var gridSpan118 = "Pricing-module--grid-span-11-8--JKx7z";
export var gridSpan119 = "Pricing-module--grid-span-11-9--JA1Wu";
export var gridSpan12 = "Pricing-module--grid-span-1-2--iEpFx";
export var gridSpan1210 = "Pricing-module--grid-span-12-10--p4sEp";
export var gridSpan1211 = "Pricing-module--grid-span-12-11--R-D5X";
export var gridSpan122 = "Pricing-module--grid-span-12-2--PV+tS";
export var gridSpan123 = "Pricing-module--grid-span-12-3--aC0-V";
export var gridSpan124 = "Pricing-module--grid-span-12-4--9EEaX";
export var gridSpan125 = "Pricing-module--grid-span-12-5--m8wz7";
export var gridSpan126 = "Pricing-module--grid-span-12-6--OyZep";
export var gridSpan127 = "Pricing-module--grid-span-12-7--bKXxS";
export var gridSpan128 = "Pricing-module--grid-span-12-8--ATvfS";
export var gridSpan129 = "Pricing-module--grid-span-12-9--D7gKH";
export var gridSpan13 = "Pricing-module--grid-span-1-3--FYtbr";
export var gridSpan14 = "Pricing-module--grid-span-1-4--Aousg";
export var gridSpan15 = "Pricing-module--grid-span-1-5--oDmiH";
export var gridSpan16 = "Pricing-module--grid-span-1-6--8A+I5";
export var gridSpan17 = "Pricing-module--grid-span-1-7--aUoEF";
export var gridSpan18 = "Pricing-module--grid-span-1-8--0BQoD";
export var gridSpan19 = "Pricing-module--grid-span-1-9--ILv4h";
export var gridSpan210 = "Pricing-module--grid-span-2-10--YbKL1";
export var gridSpan211 = "Pricing-module--grid-span-2-11--Dcz47";
export var gridSpan22 = "Pricing-module--grid-span-2-2--N9E0I";
export var gridSpan23 = "Pricing-module--grid-span-2-3--lYlka";
export var gridSpan24 = "Pricing-module--grid-span-2-4--e7-Ps";
export var gridSpan25 = "Pricing-module--grid-span-2-5--b2FJ2";
export var gridSpan26 = "Pricing-module--grid-span-2-6--hDxOu";
export var gridSpan27 = "Pricing-module--grid-span-2-7--ityy7";
export var gridSpan28 = "Pricing-module--grid-span-2-8--MD20F";
export var gridSpan29 = "Pricing-module--grid-span-2-9--YzCJT";
export var gridSpan310 = "Pricing-module--grid-span-3-10--3OF8i";
export var gridSpan311 = "Pricing-module--grid-span-3-11--38tmc";
export var gridSpan32 = "Pricing-module--grid-span-3-2--4nsTo";
export var gridSpan33 = "Pricing-module--grid-span-3-3--GJUCm";
export var gridSpan34 = "Pricing-module--grid-span-3-4--Kpzir";
export var gridSpan35 = "Pricing-module--grid-span-3-5--xsnlz";
export var gridSpan36 = "Pricing-module--grid-span-3-6--4EanW";
export var gridSpan37 = "Pricing-module--grid-span-3-7--UOXHY";
export var gridSpan38 = "Pricing-module--grid-span-3-8---PXoN";
export var gridSpan39 = "Pricing-module--grid-span-3-9--52Sya";
export var gridSpan410 = "Pricing-module--grid-span-4-10--QX3z9";
export var gridSpan411 = "Pricing-module--grid-span-4-11--trPh9";
export var gridSpan42 = "Pricing-module--grid-span-4-2--KGnY2";
export var gridSpan43 = "Pricing-module--grid-span-4-3--s8Klu";
export var gridSpan44 = "Pricing-module--grid-span-4-4--CIy17";
export var gridSpan45 = "Pricing-module--grid-span-4-5--YETTF";
export var gridSpan46 = "Pricing-module--grid-span-4-6--W8vnW";
export var gridSpan47 = "Pricing-module--grid-span-4-7--w+jDX";
export var gridSpan48 = "Pricing-module--grid-span-4-8--Ad8mU";
export var gridSpan49 = "Pricing-module--grid-span-4-9--uxTbo";
export var gridSpan510 = "Pricing-module--grid-span-5-10--pPMKx";
export var gridSpan511 = "Pricing-module--grid-span-5-11--Feiza";
export var gridSpan52 = "Pricing-module--grid-span-5-2--qsvh5";
export var gridSpan53 = "Pricing-module--grid-span-5-3--L3Iz1";
export var gridSpan54 = "Pricing-module--grid-span-5-4--ICe3q";
export var gridSpan55 = "Pricing-module--grid-span-5-5--ZxXOK";
export var gridSpan56 = "Pricing-module--grid-span-5-6--rlf2u";
export var gridSpan57 = "Pricing-module--grid-span-5-7--2RLPO";
export var gridSpan58 = "Pricing-module--grid-span-5-8--48gOc";
export var gridSpan59 = "Pricing-module--grid-span-5-9--RPC4C";
export var gridSpan610 = "Pricing-module--grid-span-6-10--nPp-L";
export var gridSpan611 = "Pricing-module--grid-span-6-11--o8eaX";
export var gridSpan62 = "Pricing-module--grid-span-6-2--stFKE";
export var gridSpan63 = "Pricing-module--grid-span-6-3--EmnvJ";
export var gridSpan64 = "Pricing-module--grid-span-6-4--+xO29";
export var gridSpan65 = "Pricing-module--grid-span-6-5--k2MvC";
export var gridSpan66 = "Pricing-module--grid-span-6-6--fpHZj";
export var gridSpan67 = "Pricing-module--grid-span-6-7--rMhui";
export var gridSpan68 = "Pricing-module--grid-span-6-8--PUMRD";
export var gridSpan69 = "Pricing-module--grid-span-6-9--64-sp";
export var gridSpan710 = "Pricing-module--grid-span-7-10--LISVJ";
export var gridSpan711 = "Pricing-module--grid-span-7-11--cZgXF";
export var gridSpan72 = "Pricing-module--grid-span-7-2--simfR";
export var gridSpan73 = "Pricing-module--grid-span-7-3--IMJJi";
export var gridSpan74 = "Pricing-module--grid-span-7-4--jCW5I";
export var gridSpan75 = "Pricing-module--grid-span-7-5--yQqIj";
export var gridSpan76 = "Pricing-module--grid-span-7-6--plKEK";
export var gridSpan77 = "Pricing-module--grid-span-7-7--8GJbo";
export var gridSpan78 = "Pricing-module--grid-span-7-8--JcuRS";
export var gridSpan79 = "Pricing-module--grid-span-7-9--78n9b";
export var gridSpan810 = "Pricing-module--grid-span-8-10--6zF2j";
export var gridSpan811 = "Pricing-module--grid-span-8-11--4sgEx";
export var gridSpan82 = "Pricing-module--grid-span-8-2--e1nQQ";
export var gridSpan83 = "Pricing-module--grid-span-8-3--4qAUD";
export var gridSpan84 = "Pricing-module--grid-span-8-4--bKnsB";
export var gridSpan85 = "Pricing-module--grid-span-8-5--O2JA7";
export var gridSpan86 = "Pricing-module--grid-span-8-6--xOY0c";
export var gridSpan87 = "Pricing-module--grid-span-8-7--XPSD+";
export var gridSpan88 = "Pricing-module--grid-span-8-8--j0Ych";
export var gridSpan89 = "Pricing-module--grid-span-8-9--YCnnh";
export var gridSpan910 = "Pricing-module--grid-span-9-10--vWbp2";
export var gridSpan911 = "Pricing-module--grid-span-9-11--7znQS";
export var gridSpan92 = "Pricing-module--grid-span-9-2--JYCgE";
export var gridSpan93 = "Pricing-module--grid-span-9-3--ofUF5";
export var gridSpan94 = "Pricing-module--grid-span-9-4--tD4g+";
export var gridSpan95 = "Pricing-module--grid-span-9-5--NFihQ";
export var gridSpan96 = "Pricing-module--grid-span-9-6--at3i8";
export var gridSpan97 = "Pricing-module--grid-span-9-7--u1IRD";
export var gridSpan98 = "Pricing-module--grid-span-9-8--TZce-";
export var gridSpan99 = "Pricing-module--grid-span-9-9--CrRTg";
export var textBreak = "Pricing-module--text-break--jbjz7";
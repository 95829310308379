import { PARYOLL_DATA } from "data/pages";
import React from "react";

import * as styles from "./Platform.module.scss";

const Platform: React.FC = () => {
  return (
    <section className={styles.Platform}>
      <div className={styles.Platform_container}>
        <header className={styles.Platform_header}>
          <h1 className={styles.Platform_header_title}>
            Do even more with an integrated financial platform for your business
          </h1>
          <p className={styles.Platform_header_text}>
            A world-class financial toolkit, and growth support for your
            business. Complete with very thoughtful human support when you need
            it.
          </p>
        </header>
        <ul className={styles.Platform_content}>
          {PARYOLL_DATA.PLATFORMS.map(({ icon, text }, index) => (
            <li key={index} className={styles.Platform_content_item}>
              <div className={styles.Platform_content_item_img}>{icon}</div>
              <p className={styles.Platform_content_item_text}>{text}</p>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Platform;

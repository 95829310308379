import React from "react";
import { PARYOLL_DATA } from "data/pages";

import * as styles from "./Operations.module.scss";

const Operations: React.FC = () => {
  return (
    <section className={styles.Operations}>
      <div className={styles.Operations_container}>
        {PARYOLL_DATA.OPERATIONS.map(({ title, desc, isAvailablie }, index) => (
          <div className={styles.Operations_container_item} key={index}>
            {!isAvailablie && (
              <p className={styles.Operations_container_item_soon}>
                AVAILABLE SOON
              </p>
            )}
            <h3 className={styles.Operations_container_item_title}>{title}</h3>
            <p className={styles.Operations_container_item_desc}>{desc}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Operations;

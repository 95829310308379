import React from "react";
import { PARYOLL_DATA } from "data/pages";

import * as styles from "./Qualities.module.scss";

const Qualities: React.FC = () => {
  return (
    <section className={styles.Qualities}>
      <header className={styles.Qualities_header}>
        <h1 className={styles.Qualities_header_title}>
          Payroll as it should be — simple and useful for your business and
          teams
        </h1>
        <p className={styles.Qualities_header_text}>
          Get started in simple steps, from team onboarding to running your
          payroll payments every month in minutes.
        </p>
      </header>

      <ul className={styles.Qualities_content}>
        {PARYOLL_DATA.QUALITIES.map(({ title, desc, img }, index) => (
          <li key={index} className={styles.Qualities_content_item}>
            <img
              src={img}
              alt={title}
              className={styles.Qualities_content_item_img}
            />

            <div className={styles.Qualities_content_item_details}>
              <h3 className={styles.Qualities_content_item_details_title}>
                {title}
              </h3>
              <p className={styles.Qualities_content_item_details_desc}>
                {desc}
              </p>
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Qualities;

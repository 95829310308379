// extracted by mini-css-extract-plugin
export var Services = "Services-module--Services--mG6cG";
export var Services_content = "Services-module--Services_content--giEr2";
export var Services_content_item = "Services-module--Services_content_item--ckCC3";
export var Services_content_item_img = "Services-module--Services_content_item_img--y-HvB";
export var Services_content_item_img_wrapper = "Services-module--Services_content_item_img_wrapper--wXhp9";
export var Services_content_item_text = "Services-module--Services_content_item_text--Bs8HF";
export var Services_header = "Services-module--Services_header--svotZ";
export var Services_header_content = "Services-module--Services_header_content--8mVH3";
export var Services_header_content_text = "Services-module--Services_header_content_text--YNkTc";
export var Services_header_content_title = "Services-module--Services_header_content_title--6Ojh3";
export var Services_header_img = "Services-module--Services_header_img--MZUGc";
export var Services_mobile = "Services-module--Services_mobile--gQpiy";
export var Services_mobile_controls = "Services-module--Services_mobile_controls--1Z7FY";
export var Services_mobile_controls_btn = "Services-module--Services_mobile_controls_btn--y9qdA";
export var Services_mobile_item = "Services-module--Services_mobile_item--DgY6e";
export var Services_mobile_item_img = "Services-module--Services_mobile_item_img--zIw98";
export var Services_mobile_item_img_wrapper = "Services-module--Services_mobile_item_img_wrapper--wweNd";
export var Services_scrollbar = "Services-module--Services_scrollbar--fQ1Gf";
export var container = "Services-module--container--oB4tE";
export var gatsbyImageWrapperConstrained = "Services-module--gatsby-image-wrapper-constrained--LLGUK";
export var gridSpan1010 = "Services-module--grid-span-10-10--nIUNl";
export var gridSpan1011 = "Services-module--grid-span-10-11--B42l5";
export var gridSpan102 = "Services-module--grid-span-10-2--ijxDE";
export var gridSpan103 = "Services-module--grid-span-10-3--zsBo-";
export var gridSpan104 = "Services-module--grid-span-10-4--X8I7c";
export var gridSpan105 = "Services-module--grid-span-10-5--QLvU7";
export var gridSpan106 = "Services-module--grid-span-10-6--uUIQW";
export var gridSpan107 = "Services-module--grid-span-10-7--mpfPg";
export var gridSpan108 = "Services-module--grid-span-10-8--2ZuNy";
export var gridSpan109 = "Services-module--grid-span-10-9--s8J2l";
export var gridSpan110 = "Services-module--grid-span-1-10--KOOkl";
export var gridSpan111 = "Services-module--grid-span-1-11--NpOIm";
export var gridSpan1110 = "Services-module--grid-span-11-10--WTR2r";
export var gridSpan1111 = "Services-module--grid-span-11-11--ba5wg";
export var gridSpan112 = "Services-module--grid-span-11-2--Og7pI";
export var gridSpan113 = "Services-module--grid-span-11-3--HElSL";
export var gridSpan114 = "Services-module--grid-span-11-4--3fdEg";
export var gridSpan115 = "Services-module--grid-span-11-5--Z8GNL";
export var gridSpan116 = "Services-module--grid-span-11-6--NSvJx";
export var gridSpan117 = "Services-module--grid-span-11-7--p5B9W";
export var gridSpan118 = "Services-module--grid-span-11-8--FgRVB";
export var gridSpan119 = "Services-module--grid-span-11-9--fxAhg";
export var gridSpan12 = "Services-module--grid-span-1-2--3h2J+";
export var gridSpan1210 = "Services-module--grid-span-12-10--I7mg5";
export var gridSpan1211 = "Services-module--grid-span-12-11--Orf7P";
export var gridSpan122 = "Services-module--grid-span-12-2--OeSH1";
export var gridSpan123 = "Services-module--grid-span-12-3--7Q72X";
export var gridSpan124 = "Services-module--grid-span-12-4--1Oxok";
export var gridSpan125 = "Services-module--grid-span-12-5--iHMNP";
export var gridSpan126 = "Services-module--grid-span-12-6--lRaJ6";
export var gridSpan127 = "Services-module--grid-span-12-7--DpjFv";
export var gridSpan128 = "Services-module--grid-span-12-8--HBRW4";
export var gridSpan129 = "Services-module--grid-span-12-9--6snq0";
export var gridSpan13 = "Services-module--grid-span-1-3--R3IQu";
export var gridSpan14 = "Services-module--grid-span-1-4--eJ7Iu";
export var gridSpan15 = "Services-module--grid-span-1-5--pk870";
export var gridSpan16 = "Services-module--grid-span-1-6--OZC3E";
export var gridSpan17 = "Services-module--grid-span-1-7--4NVIN";
export var gridSpan18 = "Services-module--grid-span-1-8--fvlB2";
export var gridSpan19 = "Services-module--grid-span-1-9--ASlKs";
export var gridSpan210 = "Services-module--grid-span-2-10--SoFrr";
export var gridSpan211 = "Services-module--grid-span-2-11--hlTf8";
export var gridSpan22 = "Services-module--grid-span-2-2--BP31z";
export var gridSpan23 = "Services-module--grid-span-2-3--wNdTE";
export var gridSpan24 = "Services-module--grid-span-2-4--pPtVM";
export var gridSpan25 = "Services-module--grid-span-2-5--8Wdxi";
export var gridSpan26 = "Services-module--grid-span-2-6--kEqwK";
export var gridSpan27 = "Services-module--grid-span-2-7--c+NEK";
export var gridSpan28 = "Services-module--grid-span-2-8--EtZ2r";
export var gridSpan29 = "Services-module--grid-span-2-9--hxUwv";
export var gridSpan310 = "Services-module--grid-span-3-10--MpEVz";
export var gridSpan311 = "Services-module--grid-span-3-11--cmJc8";
export var gridSpan32 = "Services-module--grid-span-3-2--f3olz";
export var gridSpan33 = "Services-module--grid-span-3-3--738NK";
export var gridSpan34 = "Services-module--grid-span-3-4--l8YJf";
export var gridSpan35 = "Services-module--grid-span-3-5--QpD+-";
export var gridSpan36 = "Services-module--grid-span-3-6--6M1gu";
export var gridSpan37 = "Services-module--grid-span-3-7--swSWN";
export var gridSpan38 = "Services-module--grid-span-3-8--J7F+b";
export var gridSpan39 = "Services-module--grid-span-3-9--5PYBH";
export var gridSpan410 = "Services-module--grid-span-4-10--tFdQf";
export var gridSpan411 = "Services-module--grid-span-4-11--pr2gA";
export var gridSpan42 = "Services-module--grid-span-4-2--Aca1S";
export var gridSpan43 = "Services-module--grid-span-4-3--R2NOd";
export var gridSpan44 = "Services-module--grid-span-4-4--Grvjc";
export var gridSpan45 = "Services-module--grid-span-4-5--UXHdd";
export var gridSpan46 = "Services-module--grid-span-4-6--jZcW0";
export var gridSpan47 = "Services-module--grid-span-4-7--lhnSv";
export var gridSpan48 = "Services-module--grid-span-4-8--04Dqd";
export var gridSpan49 = "Services-module--grid-span-4-9--Idz+N";
export var gridSpan510 = "Services-module--grid-span-5-10--TSMf3";
export var gridSpan511 = "Services-module--grid-span-5-11--Fkip4";
export var gridSpan52 = "Services-module--grid-span-5-2---bHqa";
export var gridSpan53 = "Services-module--grid-span-5-3--sImVg";
export var gridSpan54 = "Services-module--grid-span-5-4--NGSTG";
export var gridSpan55 = "Services-module--grid-span-5-5--7wJgI";
export var gridSpan56 = "Services-module--grid-span-5-6--qYaCR";
export var gridSpan57 = "Services-module--grid-span-5-7--OSjuj";
export var gridSpan58 = "Services-module--grid-span-5-8--i9j-T";
export var gridSpan59 = "Services-module--grid-span-5-9--aYBYO";
export var gridSpan610 = "Services-module--grid-span-6-10--0R8m4";
export var gridSpan611 = "Services-module--grid-span-6-11--jw9A6";
export var gridSpan62 = "Services-module--grid-span-6-2--f8u-U";
export var gridSpan63 = "Services-module--grid-span-6-3--t8hcW";
export var gridSpan64 = "Services-module--grid-span-6-4--E5TIC";
export var gridSpan65 = "Services-module--grid-span-6-5--t47SD";
export var gridSpan66 = "Services-module--grid-span-6-6--IswRN";
export var gridSpan67 = "Services-module--grid-span-6-7--kVgbY";
export var gridSpan68 = "Services-module--grid-span-6-8--DInIY";
export var gridSpan69 = "Services-module--grid-span-6-9--xzHzv";
export var gridSpan710 = "Services-module--grid-span-7-10--qsehJ";
export var gridSpan711 = "Services-module--grid-span-7-11--4MFd8";
export var gridSpan72 = "Services-module--grid-span-7-2--iphvK";
export var gridSpan73 = "Services-module--grid-span-7-3--4j9yR";
export var gridSpan74 = "Services-module--grid-span-7-4--Xkjin";
export var gridSpan75 = "Services-module--grid-span-7-5--G1qJj";
export var gridSpan76 = "Services-module--grid-span-7-6--JasE4";
export var gridSpan77 = "Services-module--grid-span-7-7--CKhTy";
export var gridSpan78 = "Services-module--grid-span-7-8--xUA8X";
export var gridSpan79 = "Services-module--grid-span-7-9--Zivbx";
export var gridSpan810 = "Services-module--grid-span-8-10--RnTHO";
export var gridSpan811 = "Services-module--grid-span-8-11--6GAJL";
export var gridSpan82 = "Services-module--grid-span-8-2--QQ7gX";
export var gridSpan83 = "Services-module--grid-span-8-3--WYjyI";
export var gridSpan84 = "Services-module--grid-span-8-4--P-47n";
export var gridSpan85 = "Services-module--grid-span-8-5--k6lnA";
export var gridSpan86 = "Services-module--grid-span-8-6--YY6M9";
export var gridSpan87 = "Services-module--grid-span-8-7--Bf1no";
export var gridSpan88 = "Services-module--grid-span-8-8--EnMZE";
export var gridSpan89 = "Services-module--grid-span-8-9--b3Z1Z";
export var gridSpan910 = "Services-module--grid-span-9-10--KLcry";
export var gridSpan911 = "Services-module--grid-span-9-11--+K+1k";
export var gridSpan92 = "Services-module--grid-span-9-2--0CauP";
export var gridSpan93 = "Services-module--grid-span-9-3--sAUvs";
export var gridSpan94 = "Services-module--grid-span-9-4--GkLY4";
export var gridSpan95 = "Services-module--grid-span-9-5--bJlxe";
export var gridSpan96 = "Services-module--grid-span-9-6--SHxKw";
export var gridSpan97 = "Services-module--grid-span-9-7--CcXgK";
export var gridSpan98 = "Services-module--grid-span-9-8--LwJD7";
export var gridSpan99 = "Services-module--grid-span-9-9--e7CjS";
export var textBreak = "Services-module--text-break--gyU6D";
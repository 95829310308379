// extracted by mini-css-extract-plugin
export var PayrollForTeams = "PayrollForTeams-module--PayrollForTeams--Y1EOT";
export var PayrollForTeams_content = "PayrollForTeams-module--PayrollForTeams_content---4z+7";
export var PayrollForTeams_content_item = "PayrollForTeams-module--PayrollForTeams_content_item--TOOmL";
export var PayrollForTeams_content_item_header = "PayrollForTeams-module--PayrollForTeams_content_item_header--n7ieg";
export var PayrollForTeams_content_item_header_text = "PayrollForTeams-module--PayrollForTeams_content_item_header_text--c8Y8t";
export var PayrollForTeams_content_item_header_title = "PayrollForTeams-module--PayrollForTeams_content_item_header_title--j37ZX";
export var PayrollForTeams_content_item_img = "PayrollForTeams-module--PayrollForTeams_content_item_img--dF3-b";
export var PayrollForTeams_header = "PayrollForTeams-module--PayrollForTeams_header--TSLw5";
export var PayrollForTeams_header_title = "PayrollForTeams-module--PayrollForTeams_header_title--Za4UH";
export var PayrollForTeams_mobile = "PayrollForTeams-module--PayrollForTeams_mobile--LDL-z";
export var PayrollForTeams_mobile_content = "PayrollForTeams-module--PayrollForTeams_mobile_content--PN1yy";
export var PayrollForTeams_mobile_content_header = "PayrollForTeams-module--PayrollForTeams_mobile_content_header--CVVvT";
export var PayrollForTeams_mobile_content_header_img = "PayrollForTeams-module--PayrollForTeams_mobile_content_header_img--iMqHM";
export var PayrollForTeams_mobile_content_header_text = "PayrollForTeams-module--PayrollForTeams_mobile_content_header_text--WdxUD";
export var PayrollForTeams_mobile_content_header_title = "PayrollForTeams-module--PayrollForTeams_mobile_content_header_title--JI8Eg";
export var PayrollForTeams_mobile_selectors = "PayrollForTeams-module--PayrollForTeams_mobile_selectors--3Wzih";
export var PayrollForTeams_mobile_selectors_selector = "PayrollForTeams-module--PayrollForTeams_mobile_selectors_selector--ht6Lo";
export var PayrollForTeams_mobile_selectors_selector_active = "PayrollForTeams-module--PayrollForTeams_mobile_selectors_selector_active--5ygpO";
export var container = "PayrollForTeams-module--container--Hyg4F";
export var gatsbyImageWrapperConstrained = "PayrollForTeams-module--gatsby-image-wrapper-constrained--NZDow";
export var gridSpan1010 = "PayrollForTeams-module--grid-span-10-10--3oUpY";
export var gridSpan1011 = "PayrollForTeams-module--grid-span-10-11--76N9i";
export var gridSpan102 = "PayrollForTeams-module--grid-span-10-2--41HUs";
export var gridSpan103 = "PayrollForTeams-module--grid-span-10-3--Uc+lt";
export var gridSpan104 = "PayrollForTeams-module--grid-span-10-4--Xczgt";
export var gridSpan105 = "PayrollForTeams-module--grid-span-10-5--HMPsC";
export var gridSpan106 = "PayrollForTeams-module--grid-span-10-6--R0tHg";
export var gridSpan107 = "PayrollForTeams-module--grid-span-10-7--DiE-W";
export var gridSpan108 = "PayrollForTeams-module--grid-span-10-8--DONHR";
export var gridSpan109 = "PayrollForTeams-module--grid-span-10-9--baE9Q";
export var gridSpan110 = "PayrollForTeams-module--grid-span-1-10--0dtW9";
export var gridSpan111 = "PayrollForTeams-module--grid-span-1-11--kAC5F";
export var gridSpan1110 = "PayrollForTeams-module--grid-span-11-10--zoZrW";
export var gridSpan1111 = "PayrollForTeams-module--grid-span-11-11--i3ND9";
export var gridSpan112 = "PayrollForTeams-module--grid-span-11-2--ZAmnT";
export var gridSpan113 = "PayrollForTeams-module--grid-span-11-3--pCJ3K";
export var gridSpan114 = "PayrollForTeams-module--grid-span-11-4--r9nXs";
export var gridSpan115 = "PayrollForTeams-module--grid-span-11-5--TmbuM";
export var gridSpan116 = "PayrollForTeams-module--grid-span-11-6--tN6O1";
export var gridSpan117 = "PayrollForTeams-module--grid-span-11-7--bTXRc";
export var gridSpan118 = "PayrollForTeams-module--grid-span-11-8--MfB-I";
export var gridSpan119 = "PayrollForTeams-module--grid-span-11-9--iGBkN";
export var gridSpan12 = "PayrollForTeams-module--grid-span-1-2--7CRF-";
export var gridSpan1210 = "PayrollForTeams-module--grid-span-12-10--F6lPR";
export var gridSpan1211 = "PayrollForTeams-module--grid-span-12-11--DLdPw";
export var gridSpan122 = "PayrollForTeams-module--grid-span-12-2--zwEJe";
export var gridSpan123 = "PayrollForTeams-module--grid-span-12-3--vvEVH";
export var gridSpan124 = "PayrollForTeams-module--grid-span-12-4--sp5zq";
export var gridSpan125 = "PayrollForTeams-module--grid-span-12-5--JQWec";
export var gridSpan126 = "PayrollForTeams-module--grid-span-12-6--tjbKE";
export var gridSpan127 = "PayrollForTeams-module--grid-span-12-7--mkXMd";
export var gridSpan128 = "PayrollForTeams-module--grid-span-12-8--bSY9s";
export var gridSpan129 = "PayrollForTeams-module--grid-span-12-9--tVQ7z";
export var gridSpan13 = "PayrollForTeams-module--grid-span-1-3--qwqan";
export var gridSpan14 = "PayrollForTeams-module--grid-span-1-4--+TZKR";
export var gridSpan15 = "PayrollForTeams-module--grid-span-1-5--lo9ek";
export var gridSpan16 = "PayrollForTeams-module--grid-span-1-6--mftnu";
export var gridSpan17 = "PayrollForTeams-module--grid-span-1-7--SxitT";
export var gridSpan18 = "PayrollForTeams-module--grid-span-1-8--d4smD";
export var gridSpan19 = "PayrollForTeams-module--grid-span-1-9--3x66b";
export var gridSpan210 = "PayrollForTeams-module--grid-span-2-10--XEe3a";
export var gridSpan211 = "PayrollForTeams-module--grid-span-2-11--y0EKt";
export var gridSpan22 = "PayrollForTeams-module--grid-span-2-2--g9pHM";
export var gridSpan23 = "PayrollForTeams-module--grid-span-2-3--Mz3H-";
export var gridSpan24 = "PayrollForTeams-module--grid-span-2-4--wBkAv";
export var gridSpan25 = "PayrollForTeams-module--grid-span-2-5--t8484";
export var gridSpan26 = "PayrollForTeams-module--grid-span-2-6--QpGiq";
export var gridSpan27 = "PayrollForTeams-module--grid-span-2-7--cQHju";
export var gridSpan28 = "PayrollForTeams-module--grid-span-2-8--Nau96";
export var gridSpan29 = "PayrollForTeams-module--grid-span-2-9--HCVLL";
export var gridSpan310 = "PayrollForTeams-module--grid-span-3-10--yS5Ot";
export var gridSpan311 = "PayrollForTeams-module--grid-span-3-11--0pTSA";
export var gridSpan32 = "PayrollForTeams-module--grid-span-3-2--2HQzw";
export var gridSpan33 = "PayrollForTeams-module--grid-span-3-3--VZ0Qb";
export var gridSpan34 = "PayrollForTeams-module--grid-span-3-4--7Wkzt";
export var gridSpan35 = "PayrollForTeams-module--grid-span-3-5--L4VEV";
export var gridSpan36 = "PayrollForTeams-module--grid-span-3-6--BZPZU";
export var gridSpan37 = "PayrollForTeams-module--grid-span-3-7--grQdA";
export var gridSpan38 = "PayrollForTeams-module--grid-span-3-8--YLdOC";
export var gridSpan39 = "PayrollForTeams-module--grid-span-3-9--PHxXR";
export var gridSpan410 = "PayrollForTeams-module--grid-span-4-10--9WhEB";
export var gridSpan411 = "PayrollForTeams-module--grid-span-4-11--MQRSy";
export var gridSpan42 = "PayrollForTeams-module--grid-span-4-2--ks+vP";
export var gridSpan43 = "PayrollForTeams-module--grid-span-4-3--y5jzj";
export var gridSpan44 = "PayrollForTeams-module--grid-span-4-4--3mm7w";
export var gridSpan45 = "PayrollForTeams-module--grid-span-4-5---mX-k";
export var gridSpan46 = "PayrollForTeams-module--grid-span-4-6--V6ks+";
export var gridSpan47 = "PayrollForTeams-module--grid-span-4-7--jsC39";
export var gridSpan48 = "PayrollForTeams-module--grid-span-4-8--3xOB4";
export var gridSpan49 = "PayrollForTeams-module--grid-span-4-9--JMI1F";
export var gridSpan510 = "PayrollForTeams-module--grid-span-5-10--rkNB7";
export var gridSpan511 = "PayrollForTeams-module--grid-span-5-11--L6XKg";
export var gridSpan52 = "PayrollForTeams-module--grid-span-5-2--98o8+";
export var gridSpan53 = "PayrollForTeams-module--grid-span-5-3--7QyTB";
export var gridSpan54 = "PayrollForTeams-module--grid-span-5-4--N743a";
export var gridSpan55 = "PayrollForTeams-module--grid-span-5-5--ZFegx";
export var gridSpan56 = "PayrollForTeams-module--grid-span-5-6--eYtZH";
export var gridSpan57 = "PayrollForTeams-module--grid-span-5-7--U1SZc";
export var gridSpan58 = "PayrollForTeams-module--grid-span-5-8--PXO-s";
export var gridSpan59 = "PayrollForTeams-module--grid-span-5-9--QnKsV";
export var gridSpan610 = "PayrollForTeams-module--grid-span-6-10--0x7gx";
export var gridSpan611 = "PayrollForTeams-module--grid-span-6-11--YJmye";
export var gridSpan62 = "PayrollForTeams-module--grid-span-6-2--7Qcnv";
export var gridSpan63 = "PayrollForTeams-module--grid-span-6-3--APVC4";
export var gridSpan64 = "PayrollForTeams-module--grid-span-6-4--jCjql";
export var gridSpan65 = "PayrollForTeams-module--grid-span-6-5--MGs3t";
export var gridSpan66 = "PayrollForTeams-module--grid-span-6-6--a0tN8";
export var gridSpan67 = "PayrollForTeams-module--grid-span-6-7--lm5ij";
export var gridSpan68 = "PayrollForTeams-module--grid-span-6-8--HTD-l";
export var gridSpan69 = "PayrollForTeams-module--grid-span-6-9---JDnZ";
export var gridSpan710 = "PayrollForTeams-module--grid-span-7-10--1k8as";
export var gridSpan711 = "PayrollForTeams-module--grid-span-7-11--TLurx";
export var gridSpan72 = "PayrollForTeams-module--grid-span-7-2--Lf8Li";
export var gridSpan73 = "PayrollForTeams-module--grid-span-7-3--d6JY6";
export var gridSpan74 = "PayrollForTeams-module--grid-span-7-4--rh1Di";
export var gridSpan75 = "PayrollForTeams-module--grid-span-7-5--wRWM9";
export var gridSpan76 = "PayrollForTeams-module--grid-span-7-6--5Emkp";
export var gridSpan77 = "PayrollForTeams-module--grid-span-7-7--sjAQH";
export var gridSpan78 = "PayrollForTeams-module--grid-span-7-8--qGOX5";
export var gridSpan79 = "PayrollForTeams-module--grid-span-7-9--vvezR";
export var gridSpan810 = "PayrollForTeams-module--grid-span-8-10--5D4TS";
export var gridSpan811 = "PayrollForTeams-module--grid-span-8-11--E9tf7";
export var gridSpan82 = "PayrollForTeams-module--grid-span-8-2--Z9K42";
export var gridSpan83 = "PayrollForTeams-module--grid-span-8-3--xFKkt";
export var gridSpan84 = "PayrollForTeams-module--grid-span-8-4--WOfuI";
export var gridSpan85 = "PayrollForTeams-module--grid-span-8-5--qwE2D";
export var gridSpan86 = "PayrollForTeams-module--grid-span-8-6--HKVgW";
export var gridSpan87 = "PayrollForTeams-module--grid-span-8-7--iedPP";
export var gridSpan88 = "PayrollForTeams-module--grid-span-8-8--oBheo";
export var gridSpan89 = "PayrollForTeams-module--grid-span-8-9--L-IOy";
export var gridSpan910 = "PayrollForTeams-module--grid-span-9-10--ctvKW";
export var gridSpan911 = "PayrollForTeams-module--grid-span-9-11--f5JXa";
export var gridSpan92 = "PayrollForTeams-module--grid-span-9-2--Jz2Ka";
export var gridSpan93 = "PayrollForTeams-module--grid-span-9-3--KKAYm";
export var gridSpan94 = "PayrollForTeams-module--grid-span-9-4--g0eVX";
export var gridSpan95 = "PayrollForTeams-module--grid-span-9-5--nzs1n";
export var gridSpan96 = "PayrollForTeams-module--grid-span-9-6--a0tn4";
export var gridSpan97 = "PayrollForTeams-module--grid-span-9-7--3mMEF";
export var gridSpan98 = "PayrollForTeams-module--grid-span-9-8--6NTm1";
export var gridSpan99 = "PayrollForTeams-module--grid-span-9-9--+Wta2";
export var textBreak = "PayrollForTeams-module--text-break--kC1C4";
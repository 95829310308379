import React from "react";
import { PARYOLL_DATA } from "data/pages";
import cx from "classnames";

import * as styles from "./Testimonials.module.scss";

const Testimonials: React.FC = () => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  return (
    <section className={styles.Testimonials}>
      <div className={styles.Testimonials_container}>
        <header className={styles.Testimonials_header}>
          <h1 className={styles.Testimonials_header_title}>
            Join businesses running their company payroll with Brass
          </h1>
        </header>
        <ul className={styles.Testimonials_content}>
          {PARYOLL_DATA.TESTIMONIALS.map(
            ({ name, testimony, role, company }, index) => (
              <li key={index} className={styles.Testimonials_content_item}>
                <p className={styles.Testimonials_content_item_testimony}>
                  “{testimony}“
                </p>
                <h4 className={styles.Testimonials_content_item_name}>
                  {name}
                </h4>
                <p className={styles.Testimonials_content_item_role}>
                  {role}, {company}
                </p>
              </li>
            )
          )}
        </ul>
        <div className={styles.Testimonials_mobile}>
          <div className={styles.Testimonials_mobile_content}>
            <p className={styles.Testimonials_mobile_content_testimony}>
              “{PARYOLL_DATA.TESTIMONIALS[activeIndex].testimony}“
            </p>
            <h4 className={styles.Testimonials_mobile_content_name}>
              {PARYOLL_DATA.TESTIMONIALS[activeIndex].name}
            </h4>
            <p className={styles.Testimonials_mobile_content_role}>
              {PARYOLL_DATA.TESTIMONIALS[activeIndex].role},{" "}
              {PARYOLL_DATA.TESTIMONIALS[activeIndex].company}
            </p>
          </div>
          <div className={styles.Testimonials_mobile_selectors}>
            {PARYOLL_DATA.TESTIMONIALS.map((_, index) => (
              <div
                key={index}
                className={cx(styles.Testimonials_mobile_selectors_selector, {
                  [styles.Testimonials_mobile_selectors_selector_active]:
                    activeIndex === index,
                })}
                onClick={() => setActiveIndex(index)}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;

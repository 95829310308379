// extracted by mini-css-extract-plugin
export var Testimonials = "Testimonials-module--Testimonials--AYVGp";
export var Testimonials_container = "Testimonials-module--Testimonials_container--pBMK4";
export var Testimonials_content = "Testimonials-module--Testimonials_content--OrIgz";
export var Testimonials_content_item = "Testimonials-module--Testimonials_content_item--eNCHc";
export var Testimonials_content_item_name = "Testimonials-module--Testimonials_content_item_name--mf36m";
export var Testimonials_content_item_role = "Testimonials-module--Testimonials_content_item_role--3AOhr";
export var Testimonials_content_item_testimony = "Testimonials-module--Testimonials_content_item_testimony--FBzpW";
export var Testimonials_header = "Testimonials-module--Testimonials_header--CnFa-";
export var Testimonials_header_title = "Testimonials-module--Testimonials_header_title--gDw14";
export var Testimonials_mobile = "Testimonials-module--Testimonials_mobile--1iSJH";
export var Testimonials_mobile_content = "Testimonials-module--Testimonials_mobile_content--O0Pwg";
export var Testimonials_mobile_content_name = "Testimonials-module--Testimonials_mobile_content_name--eRklU";
export var Testimonials_mobile_content_role = "Testimonials-module--Testimonials_mobile_content_role--hsHio";
export var Testimonials_mobile_content_testimony = "Testimonials-module--Testimonials_mobile_content_testimony--2af05";
export var Testimonials_mobile_selectors = "Testimonials-module--Testimonials_mobile_selectors--0+KRT";
export var Testimonials_mobile_selectors_selector = "Testimonials-module--Testimonials_mobile_selectors_selector--kuRxO";
export var Testimonials_mobile_selectors_selector_active = "Testimonials-module--Testimonials_mobile_selectors_selector_active--cn-Pg";
export var container = "Testimonials-module--container--jX4aC";
export var gatsbyImageWrapperConstrained = "Testimonials-module--gatsby-image-wrapper-constrained---qqvO";
export var gridSpan1010 = "Testimonials-module--grid-span-10-10--YIqWm";
export var gridSpan1011 = "Testimonials-module--grid-span-10-11--tj5G4";
export var gridSpan102 = "Testimonials-module--grid-span-10-2--p-uqx";
export var gridSpan103 = "Testimonials-module--grid-span-10-3--8wjgr";
export var gridSpan104 = "Testimonials-module--grid-span-10-4--t9fax";
export var gridSpan105 = "Testimonials-module--grid-span-10-5--iXGPh";
export var gridSpan106 = "Testimonials-module--grid-span-10-6--mvJwL";
export var gridSpan107 = "Testimonials-module--grid-span-10-7--CQsdn";
export var gridSpan108 = "Testimonials-module--grid-span-10-8--FDaWm";
export var gridSpan109 = "Testimonials-module--grid-span-10-9--7eSSU";
export var gridSpan110 = "Testimonials-module--grid-span-1-10--Q+b6B";
export var gridSpan111 = "Testimonials-module--grid-span-1-11--Bcotb";
export var gridSpan1110 = "Testimonials-module--grid-span-11-10--JYscw";
export var gridSpan1111 = "Testimonials-module--grid-span-11-11--KfdiE";
export var gridSpan112 = "Testimonials-module--grid-span-11-2--yrwwn";
export var gridSpan113 = "Testimonials-module--grid-span-11-3--rHUIY";
export var gridSpan114 = "Testimonials-module--grid-span-11-4--GBAuv";
export var gridSpan115 = "Testimonials-module--grid-span-11-5--nlQbE";
export var gridSpan116 = "Testimonials-module--grid-span-11-6--e3ATc";
export var gridSpan117 = "Testimonials-module--grid-span-11-7--rGSvA";
export var gridSpan118 = "Testimonials-module--grid-span-11-8--YK62F";
export var gridSpan119 = "Testimonials-module--grid-span-11-9--j8vjH";
export var gridSpan12 = "Testimonials-module--grid-span-1-2--dhp2x";
export var gridSpan1210 = "Testimonials-module--grid-span-12-10--D1f76";
export var gridSpan1211 = "Testimonials-module--grid-span-12-11--L-Nf+";
export var gridSpan122 = "Testimonials-module--grid-span-12-2--R5ioX";
export var gridSpan123 = "Testimonials-module--grid-span-12-3--U1lF5";
export var gridSpan124 = "Testimonials-module--grid-span-12-4--Tj2RH";
export var gridSpan125 = "Testimonials-module--grid-span-12-5---uZ3G";
export var gridSpan126 = "Testimonials-module--grid-span-12-6--dmJmh";
export var gridSpan127 = "Testimonials-module--grid-span-12-7--4BMwY";
export var gridSpan128 = "Testimonials-module--grid-span-12-8--jY4E3";
export var gridSpan129 = "Testimonials-module--grid-span-12-9--0tEpJ";
export var gridSpan13 = "Testimonials-module--grid-span-1-3--E1H4v";
export var gridSpan14 = "Testimonials-module--grid-span-1-4--QYZ97";
export var gridSpan15 = "Testimonials-module--grid-span-1-5--nhuEy";
export var gridSpan16 = "Testimonials-module--grid-span-1-6--VeEYK";
export var gridSpan17 = "Testimonials-module--grid-span-1-7--X-7CU";
export var gridSpan18 = "Testimonials-module--grid-span-1-8--+ajaj";
export var gridSpan19 = "Testimonials-module--grid-span-1-9--hQZW7";
export var gridSpan210 = "Testimonials-module--grid-span-2-10--FBClG";
export var gridSpan211 = "Testimonials-module--grid-span-2-11--aZOo2";
export var gridSpan22 = "Testimonials-module--grid-span-2-2--8s0Mk";
export var gridSpan23 = "Testimonials-module--grid-span-2-3--wAXjA";
export var gridSpan24 = "Testimonials-module--grid-span-2-4--RPH9L";
export var gridSpan25 = "Testimonials-module--grid-span-2-5--maEeK";
export var gridSpan26 = "Testimonials-module--grid-span-2-6--HJk8Q";
export var gridSpan27 = "Testimonials-module--grid-span-2-7--1p+OC";
export var gridSpan28 = "Testimonials-module--grid-span-2-8--xNK1T";
export var gridSpan29 = "Testimonials-module--grid-span-2-9--psGQ8";
export var gridSpan310 = "Testimonials-module--grid-span-3-10--oUPSO";
export var gridSpan311 = "Testimonials-module--grid-span-3-11--FHiS+";
export var gridSpan32 = "Testimonials-module--grid-span-3-2--vOz67";
export var gridSpan33 = "Testimonials-module--grid-span-3-3--TP9SF";
export var gridSpan34 = "Testimonials-module--grid-span-3-4--WtKPY";
export var gridSpan35 = "Testimonials-module--grid-span-3-5--qbFgL";
export var gridSpan36 = "Testimonials-module--grid-span-3-6--A23H-";
export var gridSpan37 = "Testimonials-module--grid-span-3-7--HtUAQ";
export var gridSpan38 = "Testimonials-module--grid-span-3-8--IAbh6";
export var gridSpan39 = "Testimonials-module--grid-span-3-9--Z9sK6";
export var gridSpan410 = "Testimonials-module--grid-span-4-10--5Z+V4";
export var gridSpan411 = "Testimonials-module--grid-span-4-11--j3A-G";
export var gridSpan42 = "Testimonials-module--grid-span-4-2--hoxr1";
export var gridSpan43 = "Testimonials-module--grid-span-4-3--Fekja";
export var gridSpan44 = "Testimonials-module--grid-span-4-4--SEf5J";
export var gridSpan45 = "Testimonials-module--grid-span-4-5--ZEQSA";
export var gridSpan46 = "Testimonials-module--grid-span-4-6--Jvr8t";
export var gridSpan47 = "Testimonials-module--grid-span-4-7--nTTCP";
export var gridSpan48 = "Testimonials-module--grid-span-4-8--6xjm7";
export var gridSpan49 = "Testimonials-module--grid-span-4-9--QweAE";
export var gridSpan510 = "Testimonials-module--grid-span-5-10--ymMlh";
export var gridSpan511 = "Testimonials-module--grid-span-5-11--JhAdI";
export var gridSpan52 = "Testimonials-module--grid-span-5-2--H-bck";
export var gridSpan53 = "Testimonials-module--grid-span-5-3--Cskrs";
export var gridSpan54 = "Testimonials-module--grid-span-5-4--7ryZY";
export var gridSpan55 = "Testimonials-module--grid-span-5-5--ukpen";
export var gridSpan56 = "Testimonials-module--grid-span-5-6--p6rlT";
export var gridSpan57 = "Testimonials-module--grid-span-5-7--EgOV8";
export var gridSpan58 = "Testimonials-module--grid-span-5-8--qE0iI";
export var gridSpan59 = "Testimonials-module--grid-span-5-9--wrG+l";
export var gridSpan610 = "Testimonials-module--grid-span-6-10--cHpIW";
export var gridSpan611 = "Testimonials-module--grid-span-6-11--5lcZ1";
export var gridSpan62 = "Testimonials-module--grid-span-6-2--RYXNj";
export var gridSpan63 = "Testimonials-module--grid-span-6-3--CyDTX";
export var gridSpan64 = "Testimonials-module--grid-span-6-4--zPThB";
export var gridSpan65 = "Testimonials-module--grid-span-6-5--5aORm";
export var gridSpan66 = "Testimonials-module--grid-span-6-6--HnKKN";
export var gridSpan67 = "Testimonials-module--grid-span-6-7--bZDEA";
export var gridSpan68 = "Testimonials-module--grid-span-6-8--Gc5NG";
export var gridSpan69 = "Testimonials-module--grid-span-6-9--8ZeDv";
export var gridSpan710 = "Testimonials-module--grid-span-7-10--g9r+z";
export var gridSpan711 = "Testimonials-module--grid-span-7-11--lptyv";
export var gridSpan72 = "Testimonials-module--grid-span-7-2--P1LCj";
export var gridSpan73 = "Testimonials-module--grid-span-7-3--6kkaR";
export var gridSpan74 = "Testimonials-module--grid-span-7-4--lbCt2";
export var gridSpan75 = "Testimonials-module--grid-span-7-5--nQyKl";
export var gridSpan76 = "Testimonials-module--grid-span-7-6--GaBn4";
export var gridSpan77 = "Testimonials-module--grid-span-7-7--jHQZA";
export var gridSpan78 = "Testimonials-module--grid-span-7-8--HsHcw";
export var gridSpan79 = "Testimonials-module--grid-span-7-9--eZcHK";
export var gridSpan810 = "Testimonials-module--grid-span-8-10--Zc7kT";
export var gridSpan811 = "Testimonials-module--grid-span-8-11--diU8H";
export var gridSpan82 = "Testimonials-module--grid-span-8-2--gm+ZI";
export var gridSpan83 = "Testimonials-module--grid-span-8-3--bm4+3";
export var gridSpan84 = "Testimonials-module--grid-span-8-4--0U+JS";
export var gridSpan85 = "Testimonials-module--grid-span-8-5--bhYQw";
export var gridSpan86 = "Testimonials-module--grid-span-8-6--NsCZt";
export var gridSpan87 = "Testimonials-module--grid-span-8-7--Ve1Th";
export var gridSpan88 = "Testimonials-module--grid-span-8-8--WrtkJ";
export var gridSpan89 = "Testimonials-module--grid-span-8-9--RXLir";
export var gridSpan910 = "Testimonials-module--grid-span-9-10--B-PJL";
export var gridSpan911 = "Testimonials-module--grid-span-9-11--Ar1zZ";
export var gridSpan92 = "Testimonials-module--grid-span-9-2--lb+DO";
export var gridSpan93 = "Testimonials-module--grid-span-9-3--ESczr";
export var gridSpan94 = "Testimonials-module--grid-span-9-4--UXzqI";
export var gridSpan95 = "Testimonials-module--grid-span-9-5--E-Zjk";
export var gridSpan96 = "Testimonials-module--grid-span-9-6--KmAeF";
export var gridSpan97 = "Testimonials-module--grid-span-9-7--hWI1X";
export var gridSpan98 = "Testimonials-module--grid-span-9-8--MAqxw";
export var gridSpan99 = "Testimonials-module--grid-span-9-9--vvgNa";
export var textBreak = "Testimonials-module--text-break--G9RNo";
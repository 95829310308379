import React from "react";
import {
  Customers,
  Hero,
  Operations,
  PayrollForTeams,
  Platform,
  Pricing,
  Qualities,
  RelatedPost,
  Services,
  Testimonials,
} from "components/payroll";
import { FaqWidget, GetStarted } from "components/design-system";
import { graphql, PageProps } from "gatsby";
import SEO from "components/SEO";
import pagesMeta from "data/pagesMeta";

const Payroll: React.FC<PageProps> = ({ data }) => {
  const {
    allJavascriptFrontmatter: {
      edges: [
        {
          node: { frontmatter: pageData },
        },
      ],
    },
  } = data as any;
  return (
    <>
      <SEO {...pagesMeta.payroll} />
      <Hero />
      <Qualities />
      <PayrollForTeams />
      <Operations />
      <Services />
      <Customers />
      <Testimonials />
      <Pricing />
      <Platform />
      <FaqWidget content={pageData.faqContent} />
      <RelatedPost relatedPosts={pageData.relatedPosts} />
      <GetStarted />
    </>
  );
};

export const query = graphql`
  query PayrollPageQuery {
    allJavascriptFrontmatter(
      filter: { frontmatter: { name: { eq: "payroll" } } }
    ) {
      edges {
        node {
          frontmatter {
            faqContent {
              answer
              question
            }
            relatedPosts {
              link
              title
              excerpt
              image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Payroll;

import React from "react";
import { PricingDataType } from "./types/generics";
import { IconCheckGreenLight } from "assets/media/svgs";

import * as styles from "./Pricing.module.scss";

const PricingCard: React.FC<PricingDataType> = (props) => {
  const { cost, company, additionalFee, range, feature } = props;
  return (
    <div className={styles.PricingCard}>
      <header className={styles.PricingCard_header}>
        <span>MONTHLY FEE</span>

        <p className={styles.PricingCard_header_cost}>
          {cost}{" "}
          {additionalFee && (
            <span className={styles.PricingCard_header_fee}>
              + {additionalFee}
            </span>
          )}
        </p>
        <p className={styles.PricingCard_header_company}>
          {company}{" "}
          {range && (
            <span className={styles.PricingCard_header_fee}>{range}</span>
          )}
        </p>
      </header>

      <div className={styles.PricingCard_features}>
        <span>FEATURES</span>
        <ul className={styles.PricingCard_features_data}>
          {feature.map((item, index) => (
            <li key={index} className={styles.PricingCard_features_data_item}>
              <IconCheckGreenLight /> {item.text}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PricingCard;

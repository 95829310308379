import React from "react";
import { IconCheckGreenLight, IconCircleArrow } from "assets/media/svgs";
import { PARYOLL_DATA } from "data/pages";
import cx from "classnames";
import { PricingType } from "./types/generics";

import * as styles from "./Pricing.module.scss";
import { CONTACTS_INFO_EMAILS } from "data/config";
import PricingCard from "./PricingCard";

const Pricing: React.FC = () => {
  const [type, setType] = React.useState(PricingType.Simple);
  const [activeIndex, setActiveIndex] = React.useState(0);

  const pricingLength = PARYOLL_DATA.PRICING[type].length - 1;

  return (
    <section className={styles.Pricing}>
      <div className={styles.Pricing_container}>
        <header className={styles.Pricing_header}>
          <h5 className={styles.Pricing_header_subtitle}>PAYROLL PRICING</h5>
          <h1 className={styles.Pricing_header_title}>
            Fair and transparent pricing with no hidden commitments and fees
          </h1>
        </header>

        <div className={styles.Pricing_tab}>
          {Object.keys(PARYOLL_DATA.PRICING).map((item) => (
            <button
              className={cx(styles.Pricing_tab_btn, {
                [styles.Pricing_tab_btn_active]: type === item,
              })}
              key={item}
              onClick={() => {
                setType(item as PricingType);
                setActiveIndex(0);
              }}
            >
              {item} payroll
            </button>
          ))}
        </div>

        <div className={styles.Pricing_content}>
          {type === PricingType.Standard && (
            <div className={styles.Pricing_content_standard}>
              <p className={styles.Pricing_content_standard_text}>
                Standard payroll for businesses that pay taxes, pensions and
                other statutory deductions.
              </p>
              <p className={styles.Pricing_content_standard_text}>
                For company size over 100,{" "}
                <a
                  href={CONTACTS_INFO_EMAILS.sales}
                  target="_blank"
                  rel="noopener noreferrerr"
                >
                  Contact sales
                </a>
              </p>
            </div>
          )}

          <div className={styles.Pricing_content_details}>
            {PARYOLL_DATA.PRICING[type].map((pricing, index) => (
              <PricingCard key={index} {...pricing} />
            ))}
          </div>
        </div>

        {type === PricingType.Simple && (
          <p className={styles.Pricing_simple}>
            Simple payroll for businesses that do not pay taxes, pensions and
            other statutory deductions.
          </p>
        )}

        <div className={styles.Pricing_mobile}>
          <PricingCard {...PARYOLL_DATA.PRICING[type][activeIndex]} />

          {pricingLength > 0 && (
            <div className={styles.Pricing_mobile_controls}>
              <button
                onClick={() => setActiveIndex(activeIndex - 1)}
                disabled={activeIndex === 0}
                className={styles.Pricing_mobile_controls_btn}
              >
                <IconCircleArrow />
              </button>
              <button
                onClick={() => setActiveIndex(activeIndex + 1)}
                disabled={activeIndex === PARYOLL_DATA.PRICING[type].length - 1}
                className={styles.Pricing_mobile_controls_btn}
              >
                <IconCircleArrow />
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Pricing;

// extracted by mini-css-extract-plugin
export var Qualities = "Qualities-module--Qualities--gJz5I";
export var Qualities_content = "Qualities-module--Qualities_content--eFRYh";
export var Qualities_content_item = "Qualities-module--Qualities_content_item--ER2UU";
export var Qualities_content_item_details = "Qualities-module--Qualities_content_item_details--lX2rK";
export var Qualities_content_item_details_desc = "Qualities-module--Qualities_content_item_details_desc--oEKE-";
export var Qualities_content_item_details_title = "Qualities-module--Qualities_content_item_details_title--iSdxA";
export var Qualities_content_item_img = "Qualities-module--Qualities_content_item_img--2xjBA";
export var Qualities_header = "Qualities-module--Qualities_header--EC6u-";
export var Qualities_header_text = "Qualities-module--Qualities_header_text--pulNL";
export var Qualities_header_title = "Qualities-module--Qualities_header_title--0xHHX";
export var container = "Qualities-module--container--ixHHj";
export var gatsbyImageWrapperConstrained = "Qualities-module--gatsby-image-wrapper-constrained--7WCLI";
export var gridSpan1010 = "Qualities-module--grid-span-10-10--HH3MU";
export var gridSpan1011 = "Qualities-module--grid-span-10-11--Yahgf";
export var gridSpan102 = "Qualities-module--grid-span-10-2--9jTSY";
export var gridSpan103 = "Qualities-module--grid-span-10-3--KKF3t";
export var gridSpan104 = "Qualities-module--grid-span-10-4--yeb1Y";
export var gridSpan105 = "Qualities-module--grid-span-10-5--BxzBK";
export var gridSpan106 = "Qualities-module--grid-span-10-6--iwe7P";
export var gridSpan107 = "Qualities-module--grid-span-10-7--vjUwQ";
export var gridSpan108 = "Qualities-module--grid-span-10-8--Ma2ew";
export var gridSpan109 = "Qualities-module--grid-span-10-9--3C+A5";
export var gridSpan110 = "Qualities-module--grid-span-1-10--VuIJ-";
export var gridSpan111 = "Qualities-module--grid-span-1-11--GksRS";
export var gridSpan1110 = "Qualities-module--grid-span-11-10--Sta2f";
export var gridSpan1111 = "Qualities-module--grid-span-11-11---RA3E";
export var gridSpan112 = "Qualities-module--grid-span-11-2--8Ombn";
export var gridSpan113 = "Qualities-module--grid-span-11-3---3QnZ";
export var gridSpan114 = "Qualities-module--grid-span-11-4--5gb1Q";
export var gridSpan115 = "Qualities-module--grid-span-11-5--QRakb";
export var gridSpan116 = "Qualities-module--grid-span-11-6--QQX8Q";
export var gridSpan117 = "Qualities-module--grid-span-11-7--WCayw";
export var gridSpan118 = "Qualities-module--grid-span-11-8--EWsyl";
export var gridSpan119 = "Qualities-module--grid-span-11-9--bK2eu";
export var gridSpan12 = "Qualities-module--grid-span-1-2--HbL71";
export var gridSpan1210 = "Qualities-module--grid-span-12-10--iUaoR";
export var gridSpan1211 = "Qualities-module--grid-span-12-11--lIrdd";
export var gridSpan122 = "Qualities-module--grid-span-12-2--tr6IX";
export var gridSpan123 = "Qualities-module--grid-span-12-3--VJ3z9";
export var gridSpan124 = "Qualities-module--grid-span-12-4--4mG00";
export var gridSpan125 = "Qualities-module--grid-span-12-5--QuaQI";
export var gridSpan126 = "Qualities-module--grid-span-12-6--Vdrba";
export var gridSpan127 = "Qualities-module--grid-span-12-7--9Qdfh";
export var gridSpan128 = "Qualities-module--grid-span-12-8--8vV0L";
export var gridSpan129 = "Qualities-module--grid-span-12-9--TGInV";
export var gridSpan13 = "Qualities-module--grid-span-1-3--U9usz";
export var gridSpan14 = "Qualities-module--grid-span-1-4--+3Pes";
export var gridSpan15 = "Qualities-module--grid-span-1-5--TWz+Z";
export var gridSpan16 = "Qualities-module--grid-span-1-6--AzxMF";
export var gridSpan17 = "Qualities-module--grid-span-1-7--Bj4u2";
export var gridSpan18 = "Qualities-module--grid-span-1-8---goMb";
export var gridSpan19 = "Qualities-module--grid-span-1-9--V3w2n";
export var gridSpan210 = "Qualities-module--grid-span-2-10--j8LSw";
export var gridSpan211 = "Qualities-module--grid-span-2-11--z9QzL";
export var gridSpan22 = "Qualities-module--grid-span-2-2--8gn85";
export var gridSpan23 = "Qualities-module--grid-span-2-3--lcBVc";
export var gridSpan24 = "Qualities-module--grid-span-2-4--GMHC+";
export var gridSpan25 = "Qualities-module--grid-span-2-5--yUxuE";
export var gridSpan26 = "Qualities-module--grid-span-2-6--G2mRP";
export var gridSpan27 = "Qualities-module--grid-span-2-7--a4I4J";
export var gridSpan28 = "Qualities-module--grid-span-2-8--VMS8e";
export var gridSpan29 = "Qualities-module--grid-span-2-9--Lc2j9";
export var gridSpan310 = "Qualities-module--grid-span-3-10--pUBb+";
export var gridSpan311 = "Qualities-module--grid-span-3-11--tUw0n";
export var gridSpan32 = "Qualities-module--grid-span-3-2--c5sR6";
export var gridSpan33 = "Qualities-module--grid-span-3-3--ebt1r";
export var gridSpan34 = "Qualities-module--grid-span-3-4--I9p+j";
export var gridSpan35 = "Qualities-module--grid-span-3-5--42S8f";
export var gridSpan36 = "Qualities-module--grid-span-3-6--mJxRj";
export var gridSpan37 = "Qualities-module--grid-span-3-7--wlqGi";
export var gridSpan38 = "Qualities-module--grid-span-3-8--806Ii";
export var gridSpan39 = "Qualities-module--grid-span-3-9--k-aSG";
export var gridSpan410 = "Qualities-module--grid-span-4-10--hH31H";
export var gridSpan411 = "Qualities-module--grid-span-4-11--lHLgI";
export var gridSpan42 = "Qualities-module--grid-span-4-2--eT+qC";
export var gridSpan43 = "Qualities-module--grid-span-4-3--vz4uU";
export var gridSpan44 = "Qualities-module--grid-span-4-4--4eEuZ";
export var gridSpan45 = "Qualities-module--grid-span-4-5--MFt2J";
export var gridSpan46 = "Qualities-module--grid-span-4-6--4A6Cf";
export var gridSpan47 = "Qualities-module--grid-span-4-7--oOSdK";
export var gridSpan48 = "Qualities-module--grid-span-4-8--PpID+";
export var gridSpan49 = "Qualities-module--grid-span-4-9--PgDnS";
export var gridSpan510 = "Qualities-module--grid-span-5-10--Dt+cL";
export var gridSpan511 = "Qualities-module--grid-span-5-11--Dxy9C";
export var gridSpan52 = "Qualities-module--grid-span-5-2--Zhg0A";
export var gridSpan53 = "Qualities-module--grid-span-5-3--K7+-v";
export var gridSpan54 = "Qualities-module--grid-span-5-4--D8OpA";
export var gridSpan55 = "Qualities-module--grid-span-5-5--2OPRL";
export var gridSpan56 = "Qualities-module--grid-span-5-6--z62Ft";
export var gridSpan57 = "Qualities-module--grid-span-5-7--UjN7F";
export var gridSpan58 = "Qualities-module--grid-span-5-8--7Z+Vk";
export var gridSpan59 = "Qualities-module--grid-span-5-9--BFze0";
export var gridSpan610 = "Qualities-module--grid-span-6-10--UVQZ-";
export var gridSpan611 = "Qualities-module--grid-span-6-11--h5G99";
export var gridSpan62 = "Qualities-module--grid-span-6-2--yoPSA";
export var gridSpan63 = "Qualities-module--grid-span-6-3--PkaQl";
export var gridSpan64 = "Qualities-module--grid-span-6-4--gEFEr";
export var gridSpan65 = "Qualities-module--grid-span-6-5--r-ghj";
export var gridSpan66 = "Qualities-module--grid-span-6-6--YZhFG";
export var gridSpan67 = "Qualities-module--grid-span-6-7--qbmb6";
export var gridSpan68 = "Qualities-module--grid-span-6-8--bznsL";
export var gridSpan69 = "Qualities-module--grid-span-6-9--yzacp";
export var gridSpan710 = "Qualities-module--grid-span-7-10--PqvBz";
export var gridSpan711 = "Qualities-module--grid-span-7-11--RNJXw";
export var gridSpan72 = "Qualities-module--grid-span-7-2--Vz16l";
export var gridSpan73 = "Qualities-module--grid-span-7-3--AtCLF";
export var gridSpan74 = "Qualities-module--grid-span-7-4--uAVmj";
export var gridSpan75 = "Qualities-module--grid-span-7-5--7zgWs";
export var gridSpan76 = "Qualities-module--grid-span-7-6--6wZP3";
export var gridSpan77 = "Qualities-module--grid-span-7-7--l16x0";
export var gridSpan78 = "Qualities-module--grid-span-7-8--9-wvo";
export var gridSpan79 = "Qualities-module--grid-span-7-9--wqh3P";
export var gridSpan810 = "Qualities-module--grid-span-8-10--gvY8W";
export var gridSpan811 = "Qualities-module--grid-span-8-11--IcWRu";
export var gridSpan82 = "Qualities-module--grid-span-8-2--UeXTK";
export var gridSpan83 = "Qualities-module--grid-span-8-3--u72Lq";
export var gridSpan84 = "Qualities-module--grid-span-8-4--zxEPO";
export var gridSpan85 = "Qualities-module--grid-span-8-5--FpJ0s";
export var gridSpan86 = "Qualities-module--grid-span-8-6--YlEY+";
export var gridSpan87 = "Qualities-module--grid-span-8-7--foiso";
export var gridSpan88 = "Qualities-module--grid-span-8-8--tKQXd";
export var gridSpan89 = "Qualities-module--grid-span-8-9--Si2Vy";
export var gridSpan910 = "Qualities-module--grid-span-9-10--7o6Dr";
export var gridSpan911 = "Qualities-module--grid-span-9-11--LPsSw";
export var gridSpan92 = "Qualities-module--grid-span-9-2--xXelC";
export var gridSpan93 = "Qualities-module--grid-span-9-3--sTxcI";
export var gridSpan94 = "Qualities-module--grid-span-9-4--U59Sm";
export var gridSpan95 = "Qualities-module--grid-span-9-5--3M3u2";
export var gridSpan96 = "Qualities-module--grid-span-9-6--N4bfy";
export var gridSpan97 = "Qualities-module--grid-span-9-7--KP7XT";
export var gridSpan98 = "Qualities-module--grid-span-9-8--TVaVG";
export var gridSpan99 = "Qualities-module--grid-span-9-9--ODPon";
export var textBreak = "Qualities-module--text-break--m-O-x";
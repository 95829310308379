import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import * as styles from "./RelatedPost.module.scss";

interface RelatedPostData {
  image: any;
  title: string;
  link: string;
  excerpt: string;
}

interface RelatedPostProps {
  relatedPosts: RelatedPostData[];
}

const RelatedPost: React.FC<RelatedPostProps> = ({ relatedPosts }) => {
  return (
    <section className={styles.RelatedPost}>
      <div className={styles.RelatedPost_container}>
        <header className={styles.RelatedPost_header}>
          <h1 className={styles.RelatedPost_header_title}>
            Related posts to get you started
          </h1>
        </header>
        <div className={styles.RelatedPost_content}>
          {relatedPosts.map(({ link, title, image, excerpt }, index) => {
            const postImage = getImage(image.childImageSharp.gatsbyImageData);
            return (
              <a
                href={link}
                key={index}
                target="_blank"
                rel="noopener noreferrerr"
                className={styles.RelatedPost_content_item}
              >
                <div className={styles.RelatedPost_content_item_img}>
                  <GatsbyImage image={postImage!} alt={title} />
                </div>
                <div className={styles.RelatedPost_content_item_details}>
                  <h4 className={styles.RelatedPost_content_item_details_title}>
                    {title}
                  </h4>
                  <p
                    className={styles.RelatedPost_content_item_details_excerpt}
                  >
                    {excerpt}
                  </p>
                </div>
              </a>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default RelatedPost;

import React from "react";
import { Button, ButtonVariant } from "brass-ui-kit";
import { APP_SIGNUP_URL } from "data/config";
import { window } from "browser-monads";

import * as styles from "./Hero.module.scss";
import { HERO_VIDEO } from "data/pages/payroll";

const Hero: React.FC = () => {
  return (
    <section className={styles.Hero}>
      <div className={styles.Hero_video}>
        <video
          controls={false}
          autoPlay={true}
          muted
          loop
          playsInline
          preload="auto"
          src={HERO_VIDEO}
        >
          Download the
          <a href={HERO_VIDEO}>MP4</a>
          video.
        </video>
      </div>
      <div className={styles.Hero_content}>
        <h1 className={styles.Hero_content_title}>
          The only payroll service your business will ever need
        </h1>
        <p className={styles.Hero_content_text}>
          Get started with a simple payroll service built to scale with your
          business and team needs.
        </p>
        <Button
          variant={ButtonVariant.Primary}
          className={styles.Hero_content_btn}
          onClick={() => window.open(APP_SIGNUP_URL, "_target")}
        >
          Get started in minutes
        </Button>
      </div>
    </section>
  );
};

export default Hero;
